import type { MetaFunction } from "@remix-run/node";
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";
import { RecentUserVideos } from "~/components/AppContent/Items/RecentUserVideos";
import { TemplatesList } from "~/components/AppContent/Templates/TemplatesList";
import { loader, RootLoaderData } from "~/root";
import { useLoaderData, useRouteLoaderData } from "@remix-run/react";

export const meta: MetaFunction = () => {
  return [
    { title: "Home — Paracast.io" },
    {
      name: "description",
      content: "Create videos for your SaaS and startup and grow!",
    },
  ];
};

export default function Index() {
  let { t } = useTranslation();
  const { user } = useRouteLoaderData("root") as RootLoaderData;

  return (
    <>
      <RecentUserVideos></RecentUserVideos>
      <TemplatesList></TemplatesList>
    </>
  );
}
