import { Button, Grid, Group } from "@mantine/core";
import { IconMessageDots } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "@remix-run/react";
import { RootLoaderData } from "~/root";
import { UserVideo } from "~/components/AppContent/Items/UserVideo";
import { TemplateItem } from "~/components/AppContent/Templates/TemplateItem";
import { getTemplates } from "./getTemplates";

export function TemplatesList() {
  const { user } = useRouteLoaderData("root") as RootLoaderData;
  const allTemplates = getTemplates();
  const isAuthenticated = !!user;

  return (
    <>
      <h2>Start with a template</h2>
      <Grid gutter={{ base: 5, xs: "md", md: "xl", xl: 50 }}>
        {allTemplates.map((template) => (
          <Grid.Col key={template.templateId} span={{ xs: 6, sm: 4, md: 4, lg: 3, xl: 2 }}>
            <TemplateItem 
              template={template} 
              isAuthenticated={isAuthenticated}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
}
