import { ActionIcon, Box, Button, Group, Text, Loader, rem } from "@mantine/core";
import { Link, useFetcher, useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useRef, useCallback, useEffect, useState } from "react";
import { IconCheck, IconCloudCog, IconDotsVertical } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { constants } from "~/constants";
import { TemplatePopover } from "./TemplatePopover";
import { notifications } from '@mantine/notifications';
import { getRoute } from "~/routes";
import { TemplateSignupModal } from "./TemplateSignupModal";

interface TemplateItemProps {
  template: {
    templateId: number;
    videoId: number;
    title: string;
    description: string;
    useCase: string;
    thumbnailUrl: string;
  };
  isAuthenticated: boolean;
}

export function TemplateItem({ template, isAuthenticated }: TemplateItemProps) {
  const videoRef = useRef(null);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const isLoading = fetcher.state === "submitting";

  const [signupModalOpened, setSignupModalOpened] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((error) => console.error("Error playing video:", error));
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 1.3;
    }
  }, []);

  const [opened, { close, open }] = useDisclosure(false);

  const handleTemplateClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!isAuthenticated) {
      setSignupModalOpened(true);
      return;
    }
    
    fetcher.submit(
      {},
      {
        method: "POST",
        action: getRoute('createFromTemplate', { template_id: template.templateId }),
      }
    );
  };

  useEffect(() => {
  if (fetcher.data?.error) {
      notifications.show({
        title: 'Error',
        message: fetcher.data.error,
        color: 'red',
        autoClose: false,
      });
    } else if (fetcher.data?.success) {
      notifications.show({
        title: 'Success!',
        icon: <IconCheck size="1.1rem" />,
        message: fetcher.data.message,
        color: "green",
        autoClose: 7000,
      });
      
      if (fetcher.data.video) {
        navigate(getRoute('video', { video_slug: fetcher.data.video.slug }));
      }
    }
  }, [fetcher.data]);

  return (
    <>
      <TemplatePopover
        opened={opened}
        onMouseEnter={open}
        onMouseLeave={close}
        template={template}
      >
        <div 
          className="template-item" 
          onClick={!isLoading ? handleTemplateClick : undefined} 
          style={{ 
            cursor: isLoading ? 'not-allowed' : 'pointer',
            opacity: isLoading ? 0.7 : 1,
            position: 'relative',
            aspectRatio: '16/9' // to avoid the video box from being jumped when loaded
          }}
        >
          <video
            className=""
            style={{ width: "100%" }}
            ref={videoRef}
            src={template.thumbnailUrl + "#t=1.3"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            loop
            muted
            playsInline
          ></video>
        </div>
        <Box p="xs">
          <Group justify="space-between">
            <Box>
              {isLoading ? (
                <Group>
                  <Loader size="xs" />
                  <Text size="sm" fw={500}>Creating...</Text>
                </Group>
              ) : (
                <Text size="sm" fw={500}>
                  {template.title}
                </Text>
              )}
            </Box>
          </Group>
        </Box>
      </TemplatePopover>

      <TemplateSignupModal
        opened={signupModalOpened}
        onClose={() => setSignupModalOpened(false)}
        template={template}
      />
    </>
  );
}
