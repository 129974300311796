import { Modal, Text, Box } from '@mantine/core';
import { Register } from '~/components/AppShell/Auth/Register';

interface TemplateSignupModalProps {
  opened: boolean;
  onClose: () => void;
  template: {
    title: string;
    thumbnailUrl: string;
  };
}

export function TemplateSignupModal({ opened, onClose, template }: TemplateSignupModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="md"
      title={`Create an account to use ${template.title}`}
      centered
    >
      <Box mb="md">
        <video
          style={{ width: "100%", borderRadius: 10 }}
          src={template.thumbnailUrl}
          autoPlay
          loop
          muted
          playsInline
        />
      </Box>
      
      <Register />
    </Modal>
  );
} 