import { Box, Popover, Text, Group } from "@mantine/core";
import { constants } from "~/constants";

interface TemplatePopoverProps {
  opened: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: React.ReactNode;
  template: any;
}

export function TemplatePopover({ 
  opened, 
  onMouseEnter, 
  onMouseLeave, 
  children,
  template 
}: TemplatePopoverProps) {
  return (
    <Popover
      width="400"
      position="top-start"
      withArrow
      shadow="md"
      opened={opened}
      arrowPosition="side"
    >
      <Popover.Target>
        <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {children}
        </Box>
      </Popover.Target>
      <Popover.Dropdown pt={0}>
        <h4>Description</h4>
        <Text component={"span"} size="sm">
          {template.description}
        </Text>
        <h4>Use case</h4>
        <Text component={"span"} size="sm">
          {template.useCase}
        </Text>
        <Text component={"span"} size="xs" c={"dimmed"}>
          <Group pt={"sm"} align="center">
            <img height={"30"} src={template.logoUrl} />
            <span>{template.craftedBy}</span>
          </Group>
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
} 